import React, { Component } from "react";
import profilePhoto from "../images/pp.jpg";
import downGif from "../images/downicons.gif";
import "../css/colorSet.css";

class homepage extends Component {
  render() {
    return (
      <div id="home">
        <div className="container">
          <div className="row hope min vh-100 aligin-items-center">
            <div className="row"></div>
            <div className="d-block d-md-none row"></div>
            <div className="d-block d-md-none row"></div>

            <div className="col-md-3 d-flex justify-content-center align-items-center">
              <div className="profile-set">
                <img
                  src={profilePhoto}
                  className="profile-photo"
                  alt="MyProfilePhoto"
                />
                <div className="department">
                  <h4>
                    Computer Engineer
                    <p className="opacity-25 fs-5">4th Year Student</p>
                  </h4>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block col-md-1"> </div>

            <div className="col-md-8 d-flex justify-content-center align-items-center">
              <div>
                <h1>Hi, I'm Ali</h1>

                <h2></h2>

                <br />

                <div className="opacity-75 fs-5">
                  An adventure that started with my curiosity and love for
                  technology from a very young age
                  <br /> As I gain experience and learn, I develop my own
                  projects and website.
                  <br />
                  <p></p>
                  <h5 className="opacity-75 fst-italic">
                    Software designs the future...
                  </h5>
                </div>

                <div className="mt-5">
                  <div className="d-flex justify-content-center">
                    <a
                      className="button"
                      href={() => false}
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa-solid fa-download"></i> CV{" "}
                    </a>
                    <a
                      className="button"
                      href={() => false}
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa-solid fa-globe"></i> Blog{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12"></div>

              <div className="col-md-12 justify-content-center">
                <div className="social fs-2">
                  <div className="social-links">
                    <a
                      href="https://www.linkedin.com/in/alihappy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://www.instagram.com/alihappy_/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="instagram"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://twitter.com/alihepi"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="twetter"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://github.com/alihepi"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="github"
                    >
                      <i className="fa-brands fa-github"></i>
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://www.youtube.com/channel/UCjnX0OUxao8lfqMc-KLwBpg"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="youtube"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://open.spotify.com/user/v61zjpgd5ylvpiu2c812gypnz?si=ece3a5bd7a7b417b"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="spotify"
                    >
                      <i className="fa-brands fa-spotify"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-ms-12 d-flex down-ic justify-content-center align-items-center mt-4 spc-size-set-a1">
                <img
                  src={downGif}
                  className="profile-photo"
                  alt="MyProfilePhoto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default homepage;
