import React, { Component } from 'react'
import '../css/colorSet.css'

export default class footer extends Component {
  render() {
    return (
      <div>
        <footer id='footer' className="footer-set d-flex align-items-center justify-content-center text-center">
          <div className='opacity-75'>Designed by Ali Mutlu © 2022-2025</div>
        </footer>
      </div>
    )
  }
}
