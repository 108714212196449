import React, { Component } from 'react'
import '../css/settings.css'

class AboutMe extends Component {

  render() {

    return (

      <div id='aboutMe'>

        <div className='container'>

            <div className='row hope min-vh-100 justify-content-center aligin-items-center'>
            
                <div className='row'></div>

                <div className='col-md-12 fs-5 text-center mx-auto'>

                    <h2 className='pageTittle'>About Me</h2>
                    <br/>
                    <p>
                        Hi, I'm Ali
                        <br/>
                        Let me tell you briefly about myself
                        <br/>
                        <br/>
                        I completed my primary and secondary education in Kütahya. Then I won the Computer Engineering Program at Süleyman Demirel University. I can say that my adventure, which started at a very young age, has brought me to this day. Currently, Computer Engineering 2. I'm a grad student.
                        <br/>
                        <br/>
                        I believe that I have done my best to improve myself as well as my learning. For this reason, I can define myself as a front-end developer. In addition to the programming languages I mentioned in the My Skills section (JavaScript, CSS, HTML, C#), there are also programming languages that I aim to specialize in (React, .NET). In addition to all this, I have a little knowledge that I can understand and interpret what is what in most programming languages.
                        <br/>
                        <br/>
                        All right! How about working together?
                        <br/>

                        You can reach me from my <a className='contact-ali' href="mailto:contact@alimutlu.net" rel="noopener noreferrer">contact@alimutlu.net</a> e-mail address...    
                    </p>

                </div>

            </div>
        
        </div>
        
      </div>

    )

  }
}

export default AboutMe;