import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css';
import './css/settings.css';
import './css/colorSet.css';
import './specialFontsCS/stylesheetone.css'
import React, { Component } from 'react'
import Navbar from "./components/Navbar"
import HomePage from './components/HomePage';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Footer from './components/Footer';

class App extends Component {

  render() {

    return (
      <div>

        <Navbar />

        <HomePage id='idhome' />
        <Skills id='idskills' />

        <Footer />

      </div>
    );

  }
}

export default App;